<template>
    <header class="header header--fixed" v-scroll="fixedScroll">
        <div class="header__body">
            <div class="header__inner--pc">
                <btn-hamburger-primary class="btn-hamburger--pc" />
                <div class="header__right-menu">
                    <v-row align="center" justify="center" class="flex-nowrap">
                        <v-col cols="auto">
                            <v-btn to="/contact/seoul" text color="grey lighten-1" class="v-btn--text-fit"><span class="right-menu__txt">오시는 길</span></v-btn>
                        </v-col>
                        <v-col cols="auto">
                            ·
                        </v-col>
                        <v-col cols="auto">
                            <v-btn href="https://l2y36.channel.io/home" target="_blank" text color="grey lighten-1" class="v-btn--text-fit"><span class="right-menu__txt">상담신청</span></v-btn>
                        </v-col>
                        <v-col cols="auto">
                            ·
                        </v-col>
                        <v-col cols="auto">
                            <v-btn href="tel:16618731" target="_blank" text color="grey lighten-1" class="v-btn--text-fit font-secondary"><span class="right-menu__txt">1661-8731</span></v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div class="header__inner--mobile">
                <v-container>                
                    <h1 class="header__logo">
                        <a href="/" title="홈으로 이동"><span class="d-sr-only">법무법인 태성</span></a>
                    </h1>
                    <div class="header__contents">
                        <div class="header__contents-wrap">
                            <v-row no-gutters class="header__mobile-link-group">
                                <v-col cols="6" class="header__mobile-link-wrap">
                                    <a href="https://l2y36.channel.io/home" target="_blank" class="header__mobile-link">
                                        <icon-chat />
                                        <p class="header__mobile-link-title">상담신청</p>
                                    </a>
                                </v-col>
                                <v-col cols="6" class="header__mobile-link-wrap">
                                    <a href="tel:16618731" target="_blank" class="header__mobile-link">
                                        <icon-tel />
                                        <p class="header__mobile-link-title">1661-8731</p>
                                    </a>
                                </v-col>
                            </v-row>
                            <div class="header__gnb d-block d-xl-flex justify-center">
                                <!-- S: Gnb -->
                                <client-gnb className="gnb"></client-gnb>
                                <!-- E: Gnb -->
                            </div>
                        </div>
                    </div>                
                </v-container>            
            </div>
        </div>        
        <!-- E: body -->

        <btn-hamburger-primary class="btn-hamburger--mobile" />

        <!-- S: Side gnb -->
        <div class="side-gnb-wrap">
            <v-img src="/images/common/slide-gnb-bg1.svg" max-width="1020" :aspect-ratio="1020 / 302" class="slide-gnb__bg slide-gnb__bg--1 w-100"/>
            <v-img src="/images/common/slide-gnb-bg2.svg" max-width="986" :aspect-ratio="986 / 302" class="slide-gnb__bg slide-gnb__bg--2 w-100"/>
            <v-container>
                <v-row>
                    <v-col>
                        <client-gnb className="slide-gnb"></client-gnb>
                    </v-col>
                    <v-col cols="5">
                        <v-img src="/images/common/slide-gnb-img.png" max-width="586" :aspect-ratio="586 / 470" class="slide-gnb__img"/>
                    </v-col>
                </v-row>                
            </v-container>
        </div>
        <!-- E: Side gnb -->
    </header>
</template>

<script>
import ClientGnb from "@/components/client/inc/client-gnb.vue";
import LocaleSelect from "@/components/client/inc/locale-select.vue";
import MainHeaderSearch from "./main-header-search.vue";
import BtnHamburgerPrimary from "@/components/publish/parents/buttons/btn-hamburger-primary.vue";
import IconTel from "@/components/publish/styles/icons/icon-tel.vue";
import IconChat from "@/components/publish/styles/icons/icon-chat.vue";

export default {
    components: {
        ClientGnb,
        LocaleSelect,
        MainHeaderSearch,
        BtnHamburgerPrimary,
        IconTel,
        IconChat,
    },
    data() {
        return {
            window: {
                width: 0,
                height: 0,
            },
            dialog: false,
        };
    },
    mounted() {
        window.scrollTo(0, 0); // 라우터 이동 시 스크롤 상단부터 시작

        // 전체메뉴 초기화
        pcMenuOut();

        if (this.isDesktop) {
            this.initPcEvent();
        }
        if (this.isMobile) {
            this.initMobileEvent();
        }
    },
    methods: {
        fixedScroll: function () {
            var header = document.querySelector(".header");
            var scrollTop = window.scrollY || document.documentElement.scrollTop;

            if (scrollTop > 50) {
                header.classList.add("scroll-active");
            } else {
                header.classList.remove("scroll-active");
            }
        },
        handleResize: function () {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        // PC
        initPcEvent: function () {
            // hamburgerBtnPc
            var hamburgerBtnPc = document.querySelector(".btn-hamburger--pc");
            hamburgerBtnPc.addEventListener("click", pcMenuToggle);
        },
        destroyPcEvent: function () {
            // hamburgerBtnPc
            var hamburgerBtnPc = document.querySelector(".btn-hamburger--pc");
            hamburgerBtnPc.removeEventListener("click", pcMenuToggle);
        },
        // Mobile
        initMobileEvent: function () {
            // hamburgerBtn
            var hamburgerBtnMobile = document.querySelector(".btn-hamburger--mobile");
            hamburgerBtnMobile.addEventListener("click", mobileMenuToggle);

            // gnb
            var gnbLink = document.querySelectorAll(".gnb__link:not(.gnb__link--toggle)");
            var gnbLinkToggle = document.querySelectorAll(".gnb__link--toggle");
            var gnbSubLink = document.querySelectorAll(".gnb__sub >li >a");

            gnbLinkToggle.forEach(function (el) {
                el.addEventListener("click", toggleAccordion);
            });
            gnbLink.forEach(function (el) {
                el.addEventListener("click", hideGnb);
            });
            gnbSubLink.forEach(function (el) {
                el.addEventListener("click", hideGnb);
            });
        },
        destroyMobileEvent: function () {
            // hamburgerBtn
            var hamburgerBtnMobile = document.querySelector(".btn-hamburger--mobile");
            hamburgerBtnMobile.removeEventListener("click", mobileMenuToggle);

            // gnb
            var gnbLink = document.querySelectorAll(".gnb__link:not(.gnb__link--toggle)");
            var gnbLinkToggle = document.querySelectorAll(".gnb__link--toggle");
            var gnbSubLink = document.querySelectorAll(".gnb__sub >li >a");

            gnbLinkToggle.forEach(function (el) {
                el.removeEventListener("click", toggleAccordion);
            });
            gnbLink.forEach(function (el) {
                el.removeEventListener("click", hideGnb);
            });
            gnbSubLink.forEach(function (el) {
                el.removeEventListener("click", hideGnb);
            });
        },
    },
    computed: {
        isDesktop: function () {
            return this.window.width >= 1200;
        },
        isMobile: function () {
            return this.window.width < 1200;
        },
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
        if (this.isDesktop) {
            this.destroyPcEvent();
        }
        if (this.isMobile) {
            this.destroyMobileEvent();
        }
    },
    watch: {
        isDesktop: function () {
            if (this.isDesktop) {
                this.initPcEvent();
            } else {
                this.destroyPcEvent();
            }
        },
        isMobile: function () {
            if (this.isMobile) {
                this.initMobileEvent();
            } else {
                this.destroyMobileEvent();
            }
        },
    },
};

// PC
function pcMenuToggle() {
    var HTML = document.querySelector("html");
    var header = document.querySelector(".header");
    var hamburgerBtnPc = document.querySelector(".btn-hamburger--pc");

    header.classList.toggle("pc-menu-on");
    HTML.classList.toggle("pc-menu-hidden");
    hamburgerBtnPc.classList.toggle("active");
}
function pcMenuOut() {
    var HTML = document.querySelector("html");
    var header = document.querySelector(".header");
    var hamburgerBtnPc = document.querySelector(".btn-hamburger--pc");

    header.classList.remove("pc-menu-on");
    HTML.classList.remove("pc-menu-hidden");
    hamburgerBtnPc.classList.remove("active");
}

// Mobile
function mobileMenuToggle() {
    // var HTML = document.querySelector('html');
    var header = document.querySelector(".header");
    var gnbLinkToggle = document.querySelectorAll(".gnb__link--toggle");
    var hamburgerBtnMobile = document.querySelector(".btn-hamburger--mobile");

    header.classList.toggle("mobile-menu-on");
    hamburgerBtnMobile.classList.toggle("active");
    // HTML.classList.toggle('hidden');

    if (header.classList.contains("mobile-menu-on")) {
        gnbLinkToggle.forEach(function (el) {
            el.parentNode.classList.remove("active");
        });
    }
}
function toggleAccordion(el) {
    var gnbLinkToggle = document.querySelectorAll(".gnb__link--toggle");
    var targetLi = el.currentTarget.parentNode.classList;
    // var target = el.currentTarget;

    el.preventDefault();
    if (targetLi.contains("active")) {
        targetLi.remove("active");
    } else {
        gnbLinkToggle.forEach(function (el) {
            el.parentNode.classList.remove("active");
        });
        targetLi.add("active");
    }
}
function hideGnb() {
    var header = document.querySelector(".header");
    var lis = document.querySelectorAll("gnb__li");
    var gnbLinkToggle = document.querySelectorAll(".gnb__link--toggle");

    header.classList.remove("mobile-menu-on");

    if (lis.classList.contains("active")) {
        lis.forEach(function (el) {
            el.classList.remove("active");
        });
    }
    if (header.classList.contains("mobile-menu-on")) {
        gnbLinkToggle.forEach(function (el) {
            el.parentNode.classList.remove("active");
        });
    }
}
</script>

<style lang="scss" scoped>
::v-deep{
    .theme--light.v-btn.v-btn--text.v-btn--active:before{
        opacity: 0;
    }
}
</style>
