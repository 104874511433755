var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "media-contents"
  }, [_c('swiper', {
    ref: "swiperMedia",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100",
      "options": _vm.swiperOptionMedia
    }
  }, [_vm._l(_vm.media, function (item) {
    return [!item.subOnly ? _c('swiper-slide', {
      key: item.title,
      staticClass: "swiper swiper--media"
    }, [_c('v-card', {
      staticClass: "media-card v-card--none-active-bg w-100 transparent",
      attrs: {
        "tile": ""
      },
      on: {
        "click": function ($event) {
          return _vm.open(item.video);
        }
      }
    }, [_c('v-responsive', {
      staticClass: "media-card__image image-card w-100",
      style: `background-image: url(/images/media/${item.image})`,
      attrs: {
        "aspect-ratio": 708 / 398
      }
    }), _c('v-btn', {
      staticClass: "media-card__btn v-btn--none-active-bg",
      attrs: {
        "color": "primary lighten-1"
      }
    }, [_c('icon-plus')], 1)], 1)], 1) : _vm._e()];
  })], 2), _c('v-row', {
    staticClass: "media-contents__control",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100",
      "no-gutters": "",
      "justify": "end",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-btn-prev v-btn--text-fit",
    attrs: {
      "icon": "",
      "color": "grey lighten-1"
    }
  }, [_c('icon-arrow-primary', {
    attrs: {
      "direction": "left"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "swiper-pagination"
  })]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-btn-next v-btn--text-fit",
    attrs: {
      "icon": "",
      "color": "grey lighten-1"
    }
  }, [_c('icon-arrow-primary')], 1)], 1)], 1), _c('v-bottom-sheet', {
    attrs: {
      "eager": "",
      "fullscreen": "",
      "content-class": "mw-100 h-100",
      "max-width": "100%",
      "width": "100%"
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-btn', {
    attrs: {
      "large": "",
      "absolute": "",
      "top": "",
      "right": "",
      "icon": "",
      "text": "",
      "color": "white"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('icon-plus', {
    attrs: {
      "type": "close",
      "size": "xx-large"
    }
  })], 1), _c('v-responsive', {
    attrs: {
      "max-width": "1032",
      "aspect-ratio": 1032 / 580
    }
  }, [_c('iframe', {
    staticClass: "w-100 h-100 d-block",
    attrs: {
      "src": `https://www.youtube.com/embed/${_vm.selectedVideo}`,
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "allowfullscreen": ""
    }
  })])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }